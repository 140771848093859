@font-face {
  font-family: "icomoon";
  src: url("../../src/components/icomoon-pdp/fonts/icomoon.eot?mxrsks");
  src: url("../../src/components/icomoon-pdp/fonts/icomoon.eot?mxrsks#iefix")
      format("embedded-opentype"),
    url("../../src/components/icomoon-pdp/fonts/icomoon.ttf?mxrsks")
      format("truetype"),
    url("../../src/components/icomoon-pdp/fonts/icomoon.woff?mxrsks")
      format("woff"),
    url("../../src/components/icomoon-pdp/fonts/icomoon.svg?mxrsks#icomoon")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter-7:before {
  content: "\ebd1";
}
.icon-whatsapp:before {
  content: "\eba7";
  color: #fff;
}
.icon-cod_icon:before {
  content: "\eba7";
  color: #71716e;
  font-size: 12px;
}
.icon-Express-Delivery:before {
  content: "\eba4";
  color: #71716e;
}
.icon-Share:before {
  content: "\eba5";
}
.icon-Vector:before {
  content: "\eba6";
  color: #bebebb;
}
.icon-cart_icon:before {
  content: "\eba1";
  font-size: 30px;
}
.icon-address_icon:before {
  content: "\eb9f";
}
.icon-searchIcon:before {
  content: "\eb95";
}
.icon-offers:before {
  content: "\eb8f";
}
.icon-ic_back:before {
  content: "\e998";
  color: #02475b;
}
.icon-ic_cross:before {
  content: "\e96f";
  color: #02475b;
}
.icon-ic_loginback:before {
  content: "\eafc";
  color: #02475b;
}
.icon-rightArrow:before {
  content: "\e94d";
  color: #fff;
}

.icon-order:before {
  content: "\eb9e";
}
.icon-help_icon:before {
  content: "\eba0";
}
.icon-manage_family:before {
  content: "\eb98";
}
.icon-memberships:before {
  content: "\eb9a";
}
.icon-payments_ico:before {
  content: "\eb9b";
}
.icon-logout:before {
  content: "\eb9d";
}
.icon-userIcon:before {
  content: "\eb92";
  color: #fff;
}
.icon-address_icon1:before {
  content: "\eba2";
}
.icon-ic-down_arrow:before {
  content: "\eb87";
  color: #121414;
}
.icon-Facebook:before {
  content: "\eb50";
  color: #0075fa;
}
.icon-Linkedin:before {
  content: "\eb5d";
  color: #0066b7;
}
.icon-ic_gps1:before {
  content: "\eb4f";
  color: #fff;
}
.icon-twitter-6:before {
  content: "\eb62";
  color: #1da1f2;
}
.icon-youtube-icon .path1:before {
  content: "\eb63";
  color: rgb(255, 255, 255);
}
.icon-youtube-icon .path2:before {
  content: "\eb64";
  margin-left: -1em;
  color: rgb(232, 224, 224);
}
.icon-youtube-icon .path3:before {
  content: "\eb65";
  margin-left: -1em;
  color: rgb(205, 32, 31);
}
.icon-ios-app-btn .path1:before {
  content: "\eb51";
  color: rgb(228, 228, 228);
}
.icon-ios-app-btn .path2:before {
  content: "\eb52";
  margin-left: -3.37109375em;
  color: rgb(4, 4, 4);
}
.icon-ios-app-btn .path3:before {
  content: "\eb53";
  margin-left: -3.37109375em;
  color: rgb(92, 92, 92);
}
.icon-ios-app-btn .path4:before {
  content: "\eb54";
  margin-left: -3.37109375em;
  color: rgb(124, 124, 124);
}
.icon-ios-app-btn .path5:before {
  content: "\eb55";
  margin-left: -3.37109375em;
  color: rgb(132, 132, 132);
}
.icon-google-play-btn .path1:before {
  content: "\eb5f";
  color: rgb(220, 219, 207);
}
.icon-google-play-btn .path2:before {
  content: "\eb60";
  margin-left: -3.3896484375em;
  color: rgb(4, 217, 216);
}
.icon-google-play-btn .path3:before {
  content: "\eb61";
  margin-left: -3.3896484375em;
  color: rgb(4, 4, 4);
}
.icon-google-play-btn .path4:before {
  content: "\eb66";
  margin-left: -3.3896484375em;
  color: rgb(239, 47, 72);
}
.icon-google-play-btn .path5:before {
  content: "\eb67";
  margin-left: -3.3896484375em;
  color: rgb(94, 91, 89);
}
.icon-ic_home:before {
  content: "\eaa7";
  color: #02475b;
}
.icon-ic_invoice:before {
  content: "\eaa8";
  color: #02475b;
}
.icon-ic_location:before {
  content: "\eaa9";
  color: #02475b;
}
.icon-office:before {
  content: "\eb6c";
  color: #01475b;
}
.icon-ic_arrow_forward:before {
  content: "\eaeb";
  color: #fff;
}
.icon-rxprescription:before {
  content: "\ebc6";
  color: #000;
}
